import React from 'react'

const ContactPage = () => {
  return (
    <div className='container'>
      <h1>Contact Us Page Coming Soon</h1>
    </div>
  )
}

export default ContactPage