import { Fragment, useState, useEffect } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import axios from 'axios'
import { Constants } from '../../../../utilities/Constants'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

 const CategorySelector = (props) => {
  const [ categoryList, setCategoryList ] = useState([])

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(`${Constants.url.API_GET_ALL_CATEGORIES}`)
      setCategoryList(request.data)
      return request;
    }
    fetchData()
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

 
  const [selected, setSelected] = useState(categoryList[3])
  const[ target, setTarget] = useState('')

  props.dataPush(selected)
  
  return (
    <Listbox value={selected} onChange={setSelected}>
      
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium text-gray-700">Assigned to</Listbox.Label>
          <div className="mt-1 relative">
            <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
              <span className="block truncate">{selected}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true"  />
              </span>
            </Listbox.Button>

              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {categoryList.map((category) => (
                  <Listbox.Option
                    key={category.category_id}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-blue-600' : 'text-gray-900',
                        'cursor-default select-none relative py-2 pl-8 pr-4'
                      )
                    }
                    value={category}
                    
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {category.category_name} 
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-blue-600',
                              'absolute inset-y-0 left-0 flex items-center pl-1.5'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                    
                  </Listbox.Option>
                ))}
              </Listbox.Options>
        
          </div>
        </>
      )}
      
    </Listbox>
  )
}

export default CategorySelector