import React from 'react'

const SelfBidPage = () => {
  return (
    <div className='container text-center'>
      <h1 className='self-align-center'>Self bid page</h1>
  </div>
  )
}

export default SelfBidPage
